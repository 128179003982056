<template>
  <div>
    <vs-tabs>
      <vs-tab label="Projets">
        <div class="tab-text">
          <aps-benin-ongoing-projet> </aps-benin-ongoing-projet>
        </div>
      </vs-tab>
      <vs-tab label="Contrats">
        <div class="tab-text">
          <aps-benin-contrat clientId="5" contratCounter="5"></aps-benin-contrat>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      isResetFilter: false,
      startDate: "",
      endDate: "",
      projectStatus: "",
      product: "",
      productList: [],
      allStatus: [
        { id: "", label: "Tout" },
        { id: "withQuote", label: "Prospect avec devis" },
        { id: "activeContrat", label: "Client avec contrat actif" },
        { id: "inactiveContrat", label: "Client avec contrat inactif" }
      ],
      intermediariesList: [],
      intermediary: "",
      global: {},
      formError: false,
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingUpdateProject: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "email"],
      activePrompt: false,
      //end export section
      projectSelected: [],
      crrIDs: []
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
    this.getIntermediarySys();
    this.productList = this.allProduct;
  },
  mounted() {
    if (!this.checkUserRole("prospect_client").all)
      this.$router.push({ name: "Home" });
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
    ...mapGetters("prospectClient", ["allProspectClients"]),
    ...mapGetters("general", ["rowsTable", "allProduct"])
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("prospectClient", ["applyGetAllProspectClient"]),
    ...mapActions("users", ["applyGetIntermediariesSys"]),

    displayStatus(status) {
      return this.allStatus.find(el => el.id == status).label;
    },
    checkViewProject(item) {
      setTimeout(() => {
        this.$router.push({
          name: "ProjetContrat",
          params: { clientId: item.id }
        });
      }, 100);
    },
    async getIntermediarySys() {
      let data = {
        limit: "Tout",
        skip: 0,
        type: [
          "broker_inspector",
          "broker_leader",
          "broker_merchant",
          "broker_general_agent",
          "broker"
        ]
      };
      this.loading = true;

      try {
        let intermediary = await this.applyGetIntermediariesSys(data);
        intermediary.list.forEach(el => {
          this.intermediariesList.push({
            id: el.id,
            fullname: el.person.firstname + el.person.lastname
          });
        });

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.intermediary = "";
      this.product = "";
      this.projectStatus = "";
      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
        product: this.product.id ? this.product.id : "",
        startDate: this.startDate,
        endDate: this.endDate,
        intermediary: this.intermediary.id ? this.intermediary.id : "",
        projectStatus: this.projectStatus.id ? this.projectStatus.id : ""
      });
    },
    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProspectClients.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find(type => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        // this.allProspectClients.list.forEach(el => {
        //   let jsonTmp = {
        //     type: this.profilList.find(type => type.id == el.type).label,
        //     lastname: el.person.lastname,
        //     firstname: el.person.firstname,
        //     username: el.username,
        //     email: el.email,
        //     profession: el.profession,
        //     status: el.status
        //   };
        //   tableToPrint.push(jsonTmp);
        // });

        // let headerValTmp = ["type", "firstname", "lastname", "email", "status"];

        // let headerTitleTmp = ["Profil", "Prénom(s)", "Nom", "Email", "Statut"];


this.allProspectClients.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            prospect_sexe: el.quote.civility,
            prospect_lastname: el.quote.lastname,
            prospect_firstname: el.quote.firstname,
            prospect_birthday: this.dateElement({ birthday: el.quote.birthday })
              .birthdayInfo,
            prospect_phone: el.quote.phone,
            prospect_startDateContributions: this.dateElement({
              birthday: el.quote.startDateContributions,
            }).birthdayInfo,
            prospect_email: el.quote.email,

            // profil_broker: this.profilList.find((type) => type.id == el.broker.type)
            //   ? this.profilList.find((type) => type.id == el.broker.type).label
            //   : "",
            // type_broker: this.brokerTypeList.find(
            //   (type) => type.id == el.personality
            // )
            //   ? this.brokerTypeList.find((type) => type.id == el.personality)
            //       .label
            //   : "",
            // userCode_broker: el.broker.userCode,
            // lastname_broker: el.broker.person.lastname,
            // firstname_broker: el.broker.person.firstname,
            // forSociety_broker: el.broker.person.forSociety,
            // addres_broker: el.broker.person.addres,
            // phone_broker: el.broker.person.phone,
            username_broker: `${el.broker.person.firstname} ${el.broker.person.lastname}`,
            // email_broker: el.broker.email,
            // logo_broker: this.showFileData(el.person.logo),

            // assure
            ...this.onGetAssured(el.subscription.assured),
            // subscriber
            ...this.onGetSubscriber(el.subscription.subscriber),
            // beneficiariesDeceased
            ...this.onGetBeneficiariesDeceased(
              el.subscription.beneficiariesDeceased
            ),
            // survey
            ...this.onGetSurvey(el.subscription.survey),
            ...this.onGetTarif(el.productName, el.quote),
            ref: el.ref,
            status: this.displayStatus(el.status),
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "status",
          "ref",

          // "profil_broker",
          // "type_broker",
          // "firstname_broker",
          // "lastname_broker",
          "username_broker",
          // "userCode_broker",
          // "email_broker",
          // "forSociety_broker",
          // "phone_broker",
          // "addres_broker",
          // "logo_broker",

          "prospect_sexe",
          "prospect_lastname",
          "prospect_firstname",
          "prospect_birthday",
          "prospect_phone",
          "prospect_email",
          "prospect_startDateContributions",

          "amountOfCapital",
          "corverageDuration",
          "capitalDeathAllCauses",
          "absoluteDefinitiveDisabilityCapital",
          "doublingDeathBenefit",
          "partialPermanentDisability",
          "accumulationContributions",
          "ahr_amountOfCapital",
          "ahr_contributionPeriod",
          "ahr_corverageDuration",
          "ahr_startDateContributions",
          "ahr_endDateContributions",
          "ahr_numberOfContributions",
          "ahr_accumulationOfContributions",
          "ahr_accumulationContributions",

          "subscriber_clientNumber",
          "subscriber_sexe",
          "subscriber_lastname",
          "subscriber_firstname",
          "subscriber_email",
          "subscriber_birthday",
          "subscriber_phone",
          "subscriber_commune",
          "subscriber_adress",
          "subscriber_categorySocioProfessional",
          "subscriber_familySituation",
          "subscriber_profession",
          "subscriber_identity",

          "assured_sexe",
          "assured_lastname",
          "assured_firstname",
          "assured_email",
          "assured_birthday",
          "assured_phone",
          "assured_commune",
          "assured_adress",
          "assured_categorySocioProfessional",
          "assured_familySituation",
          "assured_profession",
          "assured_identity",
          "BeneficiariesDeceased_name",
          "survey",
        ];
        // // tarif
        // "amountOfCapital", "corverageDuration","amountOfCapital", "amountOfCapital",
        // "doublingDeathBenefit","partialPermanentDisability",
        // assure
        //tarif
        // // ABQ
        // "Montant du capital", "Durée de couverture", "Capital Décès Toutes Causes",
        // "Capital Invalidité Absolue et Définitive", "Doublement du capital décès",
        // "Invalidité Partielle et Permanente",
        // // AHR
        // "Montant du capital à terme", "Périodicité des cotisations", "Durée des cotisations (en année)","Date de début des cotisations","Date de fin des cotisations","Nombre de cotisations","Cumul des cotisations mensuelles",
        // //AAE
        // "Rente annuelle à servir sur 5 ans","Décès ou Invalidité Absolue et Définitive","Périodicité des cotisations",
        // "Durée des cotisations","Date de début des cotisations","Date de fin des cotisations", "Nombre de cotisations", "Cumul des cotisations",
        //Souscripteur
        let headerTitleTmp = [
          "Etat",
          "Référence",

          // "Profil courtier",
          // "Type courtier",
          // "Prénom(s) courtier",
          // "Nom courtier",
          "Informations courtier",
          // "Code apporteur courtier",
          // "Email courtier",
          // "Société courtier",
          // "Téléphone courtier",
          // "Adresse courtier",
          // "Logo courtier",

          "Civilité prospect",
          "Nom prospect",
          "Prénoms prospect",
          "Date de naissance prospect",
          "Téléphone prospect",
          "Email prospect",
          "Date d’effet souhaitée prospect",

          "Montant du capital",
          "Durée de couverture",
          "Capital Décès Toutes Causes",
          "Capital Invalidité Absolue et Définitive",
          "Doublement du capital décès",
          "Invalidité Partielle et Permanente",
          "Cotisation",

          "Montant du capital à terme",
          "Périodicité des cotisations",
          "Durée des cotisations (en année)",
          "Date de début des cotisations",
          "Date de fin des cotisations",
          "Nombre de cotisations",
          "Cumul des cotisations mensuelles",
          "Cotisation mensuelle",

          "Numéro souscripteur",
          "Civilité souscripteur",
          "Nom souscripteur",
          "Prénoms souscripteur",
          "Email souscripteur",
          "Date de naissance souscripteur",
          "Téléphone souscripteur",
          "Commune de naissance souscripteur",
          "Adresse de résidence souscripteur",
          "Catégorie socioprofessionnelle souscripteur",
          "Situation familiale souscripteur",
          "Profession souscripteur",
          "Pièce d’identité souscripteur",

          "Civilité assuré",
          "Nom assuré",
          "Prénoms assuré",
          "Email assuré",
          "Date de naissance assuré",
          "Téléphone assuré",
          "Commune de naissance assuré",
          "Adresse de résidence assuré",
          "Catégorie socioprofessionnelle assuré",
          "Situation familiale assuré",
          "Profession assuré",
          "Pièce d’identité assuré",

          "Nom bénéficiaire ",
          "Questionnaire médical",
        ];
        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
        onGetAssured(data) {
      if (data) {
        return {
          assured_sexe: data.civility,
          assured_lastname: data.lastname,
          assured_firstname: data.firstname,
          assured_email: data.email,
          assured_birthday: this.dateElement({ birthday: data.birthday })
            .birthdayInfo,
          assured_phone: data.phone,
          assured_commune: data.commune,
          assured_adress: data.adress,
          assured_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          assured_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          assured_profession: data.profession,
          assured_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          assured_sexe: "",
          assured_lastname: "",
          assured_firstname: "",
          assured_email: "",
          assured_birthday: "",
          assured_phone: "",
          assured_commune: "",
          assured_adress: "",
          assured_categorySocioProfessional: "",
          assured_familySituation: "",
          assured_profession: "",
          assured_identity: "",
        };
      }
    },
    onGetSubscriber(data) {
      console.log("data", data);

      if (data) {
        return {
          subscriber_sexe: data.civility,
          subscriber_clientNumber: data.clientNumber,
          subscriber_lastname: data.lastname,
          subscriber_firstname: data.firstname,
          subscriber_email: data.email,
          subscriber_birthday: this.dateElement({ birthday: data.birthday })
            .birthdayInfo,
          subscriber_phone: data.phone,
          subscriber_commune: data.commune,
          subscriber_adress: data.adress,
          subscriber_categorySocioProfessional: this.allCategorySocioPro.find(
            (el) => el.id == data.categorySocioProfessional
          )
            ? this.allCategorySocioPro.find(
                (el) => el.id == data.categorySocioProfessional
              ).label
            : "",
          subscriber_familySituation: this.familySituation.find(
            (el) => el.id == data.familySituation
          )
            ? this.familySituation.find((el) => el.id == data.familySituation)
                .label
            : "",
          subscriber_profession: data.profession,
          subscriber_identity: data.identity ? data.identity.numIdentity : "",
        };
      } else {
        return {
          subscriber_sexe: "",
          subscriber_lastname: "",
          subscriber_firstname: "",
          subscriber_email: "",
          subscriber_birthday: "",
          subscriber_phone: "",
          subscriber_commune: "",
          subscriber_adress: "",
          subscriber_categorySocioProfessional: "",
          subscriber_familySituation: "",
          subscriber_profession: "",
          subscriber_identity: "",
        };
      }
    },
    onGetBeneficiariesDeceased(data) {
      if (data) {
        return {
          BeneficiariesDeceased_name:
            data.name == "myPartner" ? "Mon conjoint" : `${data.value}`,
        };
      } else {
        return {
          BeneficiariesDeceased_name: "",
        };
      }
    },
    onGetSurvey(data) {
      if (data) {
        let surveyData = [];
        data.forEach((res) => {
          if (res.response.text) {
            surveyData.push(
              `${res.question}: ${res.response.text.value} ${res.response.text.placeholder} `
            );
          } else {
            surveyData.push(
              `${res.question}: ${res.response.quickResponse ? "OUI" : "NON"}`
            );
          }
        });
        return { survey: surveyData.join("; ") };
      } else {
        return { survey: "" };
      }
    },
    onGetTarif(productName, data) {
      console.log(productName, data, "(productName, data) ");
      if (productName == "SOHU" || productName == "APF") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            amountOfCapital: propositionChoose.amountOfCapital,
            capitalDeathAllCauses: propositionChoose.capitalDeathAllCauses,
            accumulationContributions:
              propositionChoose.accumulationContributions,
            absoluteDefinitiveDisabilityCapital:
              propositionChoose.absoluteDefinitiveDisabilityCapital,
            corverageDuration: propositionChoose.corverageDuration,
            doublingDeathBenefit:
              propositionChoose.doublingDeathBenefit == true ? "Oui" : "Non",
            partialPermanentDisability:
              propositionChoose.partialPermanentDisability == true
                ? "Oui"
                : "Non",
          };
        } else {
          return {
            amountOfCapital: "",
            capitalDeathAllCauses: "",
            accumulationContributions: "",
            absoluteDefinitiveDisabilityCapital: "",
            corverageDuration: "",
            doublingDeathBenefit: "",
            partialPermanentDisability: "",
          };
        }
      } else if (productName == "AHR") {
        if (data.aboutProposition) {
          const propositionChoose = data.aboutProposition.data.find(
            (el) => el.id == data.aboutProposition.selectedId
          );
          return {
            ahr_amountOfCapital: propositionChoose.amountOfCapital,
            ahr_contributionPeriod: "Mensuelle",
            ahr_corverageDuration: propositionChoose.corverageDuration,
            ahr_startDateContributions: this.dateElement({
              birthday: data.startDateContributions,
            }).birthdayInfo,
            ahr_endDateContributions: this.dateElement({
              birthday: propositionChoose.endDateContributions,
            }).birthdayInfo,
            ahr_numberOfContributions: propositionChoose.numberOfContributions,
            ahr_accumulationOfContributions:
              propositionChoose.accumulationOfContributions,
            ahr_accumulationContributions:
              propositionChoose.accumulationContributions,
          };
        } else {
          return {
            ahr_amountOfCapital: "",
            ahr_contributionPeriod: "",
            ahr_corverageDuration: "",
            ahr_startDateContributions: "",
            ahr_endDateContributions: "",
            ahr_numberOfContributions: "",
            ahr_accumulationOfContributions: "",
            ahr_accumulationContributions: "",
          };
        }
      }
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    async fetchApplyGetAllProject(data) {
      this.loading = true;

      try {
        await this.applyGetAllProspectClient(data);

        this.total = this.allProspectClients.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    projectsSelectedDouble(item) {
      if (this.checkUserRole("prospect_client").show) {
        this.checkViewProject(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    }
  },
  watch: {
    product() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          type: this.type,
          product: this.product.id ? this.product.id : "",
          startDate: this.startDate,
          endDate: this.endDate,
          intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
          projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
        });
      }
    },
    projectStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          type: this.type,
          product: this.product.id ? this.product.id : "",
          startDate: this.startDate,
          endDate: this.endDate,
          intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
          projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
        });
      }
    },
    intermediarie() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          type: this.type,
          product: this.product.id ? this.product.id : "",
          startDate: this.startDate,
          endDate: this.endDate,
          intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
          projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          type: this.type,
          product: this.product.id ? this.product.id : "",
          startDate: this.startDate,
          endDate: this.endDate,
          intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
          projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          type: this.type,
          product: this.product.id ? this.product.id : "",
          startDate: this.startDate,
          endDate: this.endDate,
          intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
          projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
        });
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
        product: this.product.id ? this.product.id : "",
        startDate: this.startDate,
        endDate: this.endDate,
        intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
        projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        type: this.type,
        product: this.product.id ? this.product.id : "",
        startDate: this.startDate,
        endDate: this.endDate,
        intermediary: (this.intermediary && this.intermediary.id) ? this.intermediary.id : "",
        projectStatus: (this.projectStatus && this.projectStatus.id) ? this.projectStatus.id : ""
      });
    }
  },
  components: {
    ClipLoader,
    Multiselect
  }
};
</script>
